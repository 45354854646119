<template>
  <div>

    <div class="jumbotron jumbotron-fluid title__secondary">
      <div class="container"><h1 class="display-4 title-center">Privacyverklaring</h1>
        <p class="lead--centered"></p></div>
    </div>

    <div class="bg--white">
      <div class="container max-width--1000px">

        <div class="space--50"></div>
        <div class="row justify-content-center">
          <div class="col-10">
            <p>
              20 mei 2021
            </p>
            <h3>Privacyverklaring</h3>
            <p>
              Jouw privacy is voor Checkjelinkje van groot belang. Wij houden ons dan ook aan de
              relevante wet- en regelgeving over privacy, waaronder de Algemene Verordening
              Gegevensbescherming (verder: AVG). Dit betekent dat wij:
            </p>
            <ul>
              <li><strong>Onze doeleinden duidelijk vastleggen</strong>, voordat wij jouw persoonlijke gegevens verwerken, via deze privacyverklaring;</li>
              <li><strong>Zo min mogelijk persoonlijke gegevens opslaan</strong> en enkel de gegevens die nodig zijn voor onze doeleinden;</li>
              <li><strong>Expliciet toestemming vragen</strong> voor de verwerking van jouw persoonlijke gegevens, mocht toestemming verplicht zijn;</li>
              <li><strong>Benodigde beveiligingsmaatregelen treffen</strong> om jouw persoonlijke gegevens te beschermen. Wij leggen deze verplichtingen ook op aan partijen die persoonsgegevens voor ons verwerken;</li>
              <li><strong>Jouw rechten respecteren</strong>, zoals het recht op inzage, correctie of verwijdering van jouw bij ons verwerkte persoonsgegevens.</li>
            </ul>
            <p>We zijn blij met je vertrouwen in onze dienst, en zullen er alles aan doen om dat nooit te schaden. We zullen je gegevens nooit verkopen en ons blijven inspannen om ze zo goed mogelijk te beschermen.</p>
            <p>Als je vragen hebt of wilt weten wat wij precies van jou bijhouden, neem dan contact met ons op.</p>
            <br>
            <h5>Een link controleren</h5>
            <p>
              Je kunt via onze website controleren of een link waarschijnlijk veilig is. We gebruiken onze database en algoritmen om je te een zo goed mogelijk advies te geven. Wanneer je
              een link controleert verwerken we de volgende gegevens van je:
            </p>
            <ul>
              <li>Je IP-adres</li>
              <li>Internetbrowser en apparaat type</li>
              <li>De gecontroleerde URL</li>
            </ul>
            <p>
              Persoonlijke informatie (zoals je IP-adres) bewaren we tot maximaal drie maanden nadat je de link checkte.
            </p>
            <br>
            <h5>Een phishingsite melden</h5>
            <p>
              Je kunt via onze website melding maken van een website waarvan je denkt dat het om phishing gaat. Je hoeft daar geen account voor te hebben. Wanneer je een melding maakt,
              verwerken we de volgende gegevens van je:
            </p>
            <ul>
              <li>Je IP-adres</li>
              <li>Internetbrowser en apparaat type</li>
              <li>De gemelde URL</li>
            </ul>
            <p>
              Persoonlijke informatie (zoals je IP-adres) bewaren we tot maximaal drie maanden nadat je de link checkte. De gemelde URL kunnen we langer opslaan.
            </p>
            <br>
            <h4>Verstrekking aan andere bedrijven of instellingen</h4>
            <p>Met uitzondering van de partijen die nodig zijn om de hierboven genoemde diensten te
            leveren, geven wij jouw persoonsgegevens onder geen voorwaarde aan andere bedrijven of
            instellingen, behalve als wij dat wettelijk verplicht zijn (bijvoorbeeld als de politie dat eist bij
              een vermoeden van een misdrijf).</p>
            <br>
            <h4>Statistieken</h4>
            <p>Wij houden statistieken bij over het gebruik van onze website, maar deze gegevens zijn altijd geanonimiseerd.</p>
            <br>
            <h4>Beveiliging</h4>
            <p>Beveiliging van persoonsgegevens is voor ons van groot belang. Wij zorgen dat jouw
            gegevens bij ons goed beveiligd zijn. Wij passen de beveiliging steeds aan en letten goed op
              wat er mis kan gaan.</p>
            <br>
            <h4>Wijzigingen in deze privacyverklaring</h4>
            <p>Wanneer onze website wijzigt, moeten wij natuurlijk ook de privacyverklaring aanpassen.
            Let dus altijd op de datum hierboven en kijk regelmatig of er nieuwe versies zijn. Wij zullen
              ons best doen wijzigingen aan je door te geven.</p>
            <br>
            <h4>Jouw rechten</h4>
            <p>Als je vragen hebt of wilt weten welke persoonsgegevens wij van jou hebben, kun je altijd
              contact met ons opnemen. Zie de contactgegevens hieronder.</p>
            <br>
            <p>Je hebt de volgende rechten:</p>
            <ul>
              <li>Uitleg krijgen over welke persoonsgegevens wij hebben en wat wij daarmee doen;</li>
              <li>Inzage in de precieze persoonsgegevens die wij hebben;</li>
              <li>Het laten corrigeren van fouten;</li>
              <li>Het laten verwijderen van verouderde persoonsgegevens;</li>
              <li>Het laten verwijderen van verouderde persoonsgegevens;</li>
              <li>Intrekken van toestemming;</li>
              <li>Een bepaalde verwerking beperken;</li>
              <li>Bezwaar maken tegen een bepaald gebruik.</li>
            </ul>
            <br>
            <p>Let op dat je altijd duidelijk aangeeft wie je bent, zodat we zeker weten dat wij geen
            gegevens van de verkeerde persoon aanpassen of verwijderen.</p>
            <p>Wij zullen in principe binnen een maand aan jouw verzoek voldoen. Deze termijn kan echter
            worden verlengd om redenen die verband houden met de specifieke rechten van
            betrokkenen of de complexiteit van het verzoek. Als wij deze termijn verlengen, zullen wij je
              daarvan tijdig op de hoogte stellen.</p>
            <br>
            <h4>Klacht indienen</h4>
            <p>Als je een klacht in wil dienen over het gebruik van jouw persoonsgegevens kun je een email sturen naar support@checkjelinkje.nl. Wij pakken elke klacht intern op en
              communiceren dit verder met je.</p>
            <p>Als je vindt dat wij je niet op de juiste manier helpen, dan heb je het recht om een klacht in
              te dienen bij de toezichthouder. Deze heet <a
                  href="https://autoriteitpersoonsgegevens.nl/nl/zelf-doen/gebruik-uw-privacyrechten/klacht-melden-bij-de-ap" target="_blank" rel="nofollow">de Autoriteit Persoonsgegevens <i class="far fa-external-link"></i></a>.</p>
            <br>
            <h4>Contactgegevens</h4>
              <p>Checkjelinkje B.V.<br>
              Plantsoenstraat 75<br>
              7001 AB Doetinchem<br>
              E-mailadres: support@checkjelinkje.nl<br>
              KvK nummer: 82862222
              </p>
            <br><br>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Privacyverklaring'
}
</script>
